import React from "react"

function ContainerMainSection(props) {
  return (
    <div className="main-section">
      {props.children}
    </div>

  )
}

export default ContainerMainSection